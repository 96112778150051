@media print {

	/* Margins */

	@page {
		margin: 2cm;
	}

	/* Paddings */

	// ...

	/* Width */

	// ...

	/* Fonts */

	// ...

	/* Page breaks */

	// ...
}
